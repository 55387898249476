declare global {
  interface Window {
    lwhb:
      | {
          load: (ad: Element) => void;
          loaded: boolean;
          cmd: Array<() => void>;
        }
      | undefined;
  }
}

function handleLoadAdInDevelopment(el: HTMLElement) {
  console.log('DEVELOPMENT: loading FAKE ad: ', el.dataset.tid);
  const txsParent = el.parentElement;
  if (txsParent?.classList.contains('txs-a')) {
    el.innerText = el.attributes.getNamedItem('data-tid')?.value ?? 'unknown';
  }
}

function createLeeAdObserver() {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (
            entry.target instanceof HTMLElement &&
            entry.target?.dataset.custom === 'true' &&
            entry.target?.dataset.pbid
          ) {
            if (process.env.NODE_ENV === 'development') {
              handleLoadAdInDevelopment(entry.target);
            } else {
              if (window.lwhb?.loaded) {
                window.lwhb.load(entry.target);
              } else {
                window.lwhb?.cmd.push(() => window.lwhb?.load(entry.target));
              }
            }
            observer.unobserve(entry.target);
          }
        }
      });
    },
    {
      root: null,
      rootMargin: '600px',
    },
  );
  return {
    add: (nodes) => {
      nodes =
        Array.isArray(nodes) || nodes instanceof NodeList
          ? [...nodes]
          : [nodes];

      nodes.forEach((ad) => {
        if (ad.dataset.custom === 'true') {
          observer.observe(ad);
        }
      });
    },
  };
}

export default createLeeAdObserver;
